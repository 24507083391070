<template>
  <v-layout row wrap>
    <v-flex xs12>
      <app-title title="Horarios de entrega especiales" subtitle="Habilitar o bloquear horarios por día">
        <template slot="button">
          <v-btn icon dark @click="dialogDate = true">
            <v-icon dark>add</v-icon>
          </v-btn>
        </template>
      </app-title>
    </v-flex>
    <template v-if="dialogDate">
      <v-flex xs4>
        <v-card>
          <app-title-card title="Modifique horarios de entrega"></app-title-card>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="form.date"
              type="date"
              label="Fecha a modificar horarios"
              hide-details
            ></v-text-field>
          </v-card-text>
          <v-subheader>¿Que desea hacer en esta fecha?</v-subheader>
          <v-radio-group v-model="form.type" class="ml-3">
            <v-radio color="success" label="Horario especial" :value="0"></v-radio>
            <v-radio color="accent" label="Bloquear horarios" :value="1"></v-radio>
            <v-radio color="warning" label="Limite de horario" :value="2"></v-radio>
          </v-radio-group>
        </v-card>
      </v-flex>
      <v-flex xs8>
        <v-card v-if="form.type === 0 || form.type === 1" :color="!form.type ? 'success' : 'accent'" dark>
          <app-title-card :title="form.type ? 'Comunas a bloquear' : 'Seleccione comunas y horarios'"></app-title-card>
          <v-divider></v-divider>
          <v-card-text>
            <p v-if="form.type">
              Seleccione las comunas donde no aceptara pedidos para el dia
              <span style="font-weight:800;">{{ $moment(form.date).format('dddd DD-MM-YYYY') }}</span>
            </p>
            <p v-else-if="!form.type">
              Seleccione las comunas y horarios de entrega especiales para el dia
              <span style="font-weight:800;">{{ $moment(form.date).format('dddd DD-MM-YYYY') }}</span
              ><br />
            </p>
            <v-select
              v-model="selectedStates"
              :items="states"
              :item-text="
                e => {
                  const price = $options.filters.toPrice(e.value)
                  return `${e.name} (${price})`
                }
              "
              item-value="id"
              label="Listado de comunas"
              multiple
              chips
              attach
              hide-details
            >
              <template slot="prepend-item">
                <v-list-tile ripple @click="toggle">
                  <v-list-tile-action>
                    <v-icon :color="selectedStates.length > 0 ? 'secondary' : ''">{{ icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Todos</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="my-2"></v-divider>
                <v-list-tile ripple @click="selectStates(3000, true)">
                  <v-list-tile-action>
                    <v-icon :color="selectedStates.length > 0 ? 'secondary' : ''">{{ icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Las mayores a $3.000</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile ripple @click="selectStates(3000, false)">
                  <v-list-tile-action>
                    <v-icon :color="selectedStates.length > 0 ? 'secondary' : ''">{{ icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Las menores de $3.000</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="my-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="white--text caption">(+{{ selectedStates.length - 1 }} comunas)</span>
              </template>
            </v-select>
            <template v-if="form.type === 0">
              <v-select
                v-model="selectedSchedules"
                :items="schedules"
                :item-text="
                  e => {
                    return $options.filters.toHTML(e.name)
                  }
                "
                item-value="id"
                label="Listado de horarios"
                hint="* Estos horarios sustituiran los horarios por defecto del día *"
                multiple
                chips
                attach
                persistent-hint
              >
                <template slot="prepend-item">
                  <v-list-tile ripple @click="toggleSchedules">
                    <v-list-tile-action>
                      <v-icon :color="selectedSchedules.length > 0 ? 'secondary' : ''">{{ iconSchedule }}</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Todos</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider class="my-2"></v-divider>
                </template>
                <template slot="selection" slot-scope="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span v-html="item.name"></span>
                  </v-chip>
                  <span v-if="index === 1" class="white--text caption"
                    >(+{{ selectedStates.length - 1 }} horarios)</span
                  >
                </template>
              </v-select>
              <v-checkbox
                v-model="specialSchedule"
                color="accent"
                :label="`Bloquear las comunas que no estan seleccionadas`"
              ></v-checkbox>
            </template>
          </v-card-text>
          <v-divider class="my-0"></v-divider>
          <v-card-actions>
            <v-btn flat @click="resetForm"> Cerrar </v-btn>
            <v-spacer></v-spacer>
            <v-btn flat @click="updateDateSchedule"> Ingresar </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="form.type === 2" :color="'warning'" dark>
          <app-title-card :title="'Limite de pedidos'" />
          <v-divider></v-divider>
          <v-card-text>
            <p>
              Seleccione los horarios que tendran limite de entregas para el dia
              <span style="font-weight:800;">{{ $moment(form.date).format('dddd DD-MM-YYYY') }}</span
              ><br />
            </p>
            <v-select
              v-model="selectedSchedules"
              :items="schedules"
              :item-text="
                e => {
                  return
                }
              "
              item-value="id"
              label="Listado de horarios"
              hint="* Estos horarios sustituiran los horarios por defecto del día *"
              multiple
              chips
              attach
              persistent-hint
            >
              <template slot="item" slot-scope="{ item, index }">
                <v-list-tile-action>
                  <v-icon v-if="selectedSchedules.filter(e => e === item.id).length > 0" color="secondary">
                    check_box
                  </v-icon>
                  <v-icon v-else>
                    check_box_outline_blank
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ $options.filters.toHTML(item.name) }}
                    <small>{{ `(Hora corte: ${item.hour_day} hrs)` }}</small>
                  </v-list-tile-title>
                </v-list-tile-content>
              </template>
              <template slot="selection" slot-scope="{ item, index }">
                <v-chip v-if="index === 0">
                  <span v-html="item.name"></span>
                </v-chip>
                <span v-if="index === 1" class="white--text caption"
                  >(+{{ selectedSchedules.length - 1 }} horarios)</span
                >
              </template>
            </v-select>
            <div>
              <v-data-table
                :headers="headers"
                :items="selectedLimits"
                class="elevation-0"
                style="margin-top: 20px;"
                hide-actions
                disable-initial-sort
              >
                <template v-slot:items="props">
                  <td>{{ props.item.name }}</td>
                  <td class="text-xs-right">
                    <v-text-field v-model="props.item.limit" label="Limite"></v-text-field>
                  </td>
                </template>
              </v-data-table>
            </div>
            <v-select
              v-model="selectedStates"
              :items="states"
              :item-text="
                e => {
                  const price = $options.filters.toPrice(e.value)
                  return `${e.name} (${price})`
                }
              "
              item-value="id"
              label="Solo en estas comunas"
              multiple
              chips
              attach
              hide-details
              style="margin-top: 15px;"
            >
              <template slot="prepend-item">
                <v-list-tile ripple @click="toggle">
                  <v-list-tile-action>
                    <v-icon :color="selectedStates.length > 0 ? 'secondary' : ''">{{ icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Todos</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
              <template slot="selection" slot-scope="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="white--text caption">(+{{ selectedStates.length - 1 }} comunas)</span>
              </template>
            </v-select>
          </v-card-text>
          <v-divider class="my-0"></v-divider>
          <v-card-actions>
            <v-btn flat @click="resetForm"> Cerrar </v-btn>
            <v-spacer></v-spacer>
            <v-btn flat @click="updateDateSchedule"> Ingresar </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-divider class="my-2"></v-divider>
      </v-flex>
    </template>
    <v-flex xs12 lg12 xl12>
      <v-card class="pt-0">
        <v-card-text class="px-0 pt-0 pb-0 mt-0">
          <v-sheet>
            <v-calendar
              ref="calendar"
              v-model="start"
              locale="es-es"
              :hide-header="true"
              :value="'YYYY-MM-DD'"
              :type="type"
              :end="end"
              :today="today"
              color="primary"
            >
              <template slot="day" slot-scope="item">
                <template v-for="(event, index) in eventsMap[item.date]">
                  <v-chip
                    v-if="event.type === 1 || event.type === 0"
                    :key="index"
                    class="mt-0 mb-1"
                    :color="event.type === 1 ? 'error' : 'info'"
                    close
                    label
                    small
                    dark
                    @click="editEvent(event)"
                    @input="deleteOption(event)"
                  >
                    {{ event.type === 1 ? 'Bloqueado' : 'Horario' }}
                  </v-chip>
                  <v-chip
                    v-if="event.type === 2"
                    :key="index"
                    class="mt-0 mb-1"
                    :color="'warning'"
                    close
                    label
                    small
                    dark
                    @click="editEvent(event)"
                    @input="deleteOption(event)"
                  >
                    Limite
                  </v-chip>
                </template>
                <template v-if="$moment(start).month() === $moment(item.date).month()">
                  <v-btn absolute icon small class="btn-add" @click="newEvent(item.date)">
                    <v-icon small>
                      add
                    </v-icon>
                  </v-btn>
                </template>
              </template>
            </v-calendar>
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click="$refs.calendar.prev()">
            <v-icon left dark>
              keyboard_arrow_left
            </v-icon>
            Anterior
          </v-btn>
          <v-spacer></v-spacer>
          {{ $moment(start).format('MMMM') | capitalize }}
          <v-spacer></v-spacer>
          <v-btn flat @click="$refs.calendar.next()">
            Siguiente
            <v-icon right dark>
              keyboard_arrow_right
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-btn fab dark fixed bottom right color="accent" @click="dialogDate = true">
      <v-icon dark>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import appTitle from '../useful/title.vue'
import appTitleCard from '../useful/titleCard.vue'
import { MI_STORE } from '../../config'

export default {
  watch: {
    // eslint-disable-next-line no-undef
    start: _.debounce(function debounce(e) {
      this.getDatesSchedules()
    }, 500),
    selectedSchedules(ele) {
      const schedules = this.schedules
      const selectedLimits = []
      ele.forEach(e => {
        const res = schedules.find(sch => sch.id === e)
        selectedLimits.push({
          id: res.id,
          name: res.name,
          limit: 0
        })
      })
      this.selectedLimits = selectedLimits
    }
  },
  components: {
    appTitleCard,
    appTitle
  },
  data: () => ({
    dialogDate: false,
    specialSchedule: false,
    type: 'month',
    start: '2019-01-01',
    end: '2019-01-06',
    today: '2019-01-08',
    selectedLimits: [],
    selectedStates: [],
    selectedSchedules: [],
    selectedSchedulesLimit: [],
    headers: [
      {
        text: 'Horario',
        sortable: false,
        value: 'name'
      },
      { text: 'Limite', value: 0 }
    ],
    form: {
      date: '2019-01-08',
      type: 1
    },
    events: []
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    schedules() {
      return this._.orderBy(this.$store.getters.getSchedules, ['name'], ['asc'])
    },
    states() {
      const user = this.$store.getters.getUser
      const communes = this._.filter(this.$store.getters.getCommunes, ['shopId', user.shopID])
      return this._.orderBy(communes, ['value'], ['asc'])
    },
    likeAllStates() {
      return this.selectedStates.length === this.states.length
    },
    likesSomeStates() {
      return this.selectedStates.length > 0 && !this.likeAllStates
    },
    likeAllSchedules() {
      return this.selectedSchedules.length === this.schedules.length
    },
    likesSomeSchedules() {
      return this.selectedSchedules.length > 0 && !this.likeAllSchedules
    },
    icon() {
      if (this.likeAllStates) return 'check_box'
      if (this.likesSomeStates) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    },
    iconSchedule() {
      if (this.likeAllSchedules) return 'check_box'
      if (this.likesSomeSchedules) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    },
    eventsMap() {
      const map = {}
      this.events.forEach(e => {
        map[e.date] = map[e.date] === undefined ? [] : map[e.date]
        map[e.date].push(e)
      })
      return map
    }
  },
  mounted() {
    this.start = this.$moment().format('YYYY-MM-DD')
    this.end = '2019-01-06'
    this.today = this.$moment().format('YYYY-MM-DD')
  },
  methods: {
    async getDatesSchedules() {
      try {
        const res = await this.$http.get(
          `${MI_STORE}/schedules?${this.$qs.stringify({
            start: this.start
          })}`
        )
        this.events = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateDateSchedule() {
      try {
        await this.$http.post(`${MI_STORE}/schedules`, {
          date: this.form.date,
          type: this.form.type,
          states: this.selectedStates,
          schedules: this.selectedSchedules,
          specialSchedule: this.specialSchedule,
          selectedLimits: this.selectedLimits
        })
        this.$store.dispatch('setConfirm', { active: false })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Evento Agregado'
        })
        this.resetForm()
        this.getDatesSchedules()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteOptionAction(event) {
      try {
        await this.$http.delete(
          `${MI_STORE}/schedules?${this.$qs.stringify({
            date: event.date,
            type: event.type
          })}`
        )
        this.$store.dispatch('setConfirm', { active: false })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Evento Eliminado'
        })
        this.getDatesSchedules()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    selectStates(price, more) {
      this.selectedStates = []
      if (more === undefined) {
        more = true
      }
      this.states.forEach(element => {
        if (element.value > price && more) {
          this.selectedStates.push(element.id)
        } else if (element.value <= price && !more) {
          this.selectedStates.push(element.id)
        }
      })
    },
    handleClickState(index) {
      this.states[index].selected = true
    },
    open(event) {
      alert(event.title)
    },
    editEvent(event) {
      this.dialogDate = true
      this.form.date = event.date
      this.form.type = event.type
      if (event.type === 1 || event.type === 0) {
        this.selectedStates = event.states != null ? event.states : []
        this.selectedSchedules = event.schedules != null ? event.schedules : []
      }
      if (event.type === 2) {
        this.selectedSchedules = event.schedules.map(x => {
          return x.id
        })
        this.selectedStates = event.states != null ? event.states : []
        setTimeout(() => {
          this.selectedLimits = event.schedules != null ? event.schedules : []
        }, 100)
      }
    },
    newEvent(date) {
      this.dialogDate = true
      this.form.date = date
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likeAllStates) {
          this.selectedStates = []
        } else {
          this.selectedStates = []
          this.states.forEach(element => {
            this.selectedStates.push(element.id)
          })
        }
      })
    },
    clickDate(event) {
      this.start = event.date
    },
    toggleSchedules() {
      this.$nextTick(() => {
        if (this.likeAllSchedules) {
          this.selectedSchedules = []
        } else {
          this.selectedSchedules = this.schedules.slice()
        }
      })
    },
    deleteOption(event) {
      const block = event.type === 1 ? 'Bloqueo' : 'Horario Especial'
      const date = this.$moment(event.date).format('DD-MM-YYYY')
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar evento?',
        description: `Esta seguro que desea eliminar el ${block} para el dia ${date}`,
        action: () => this.deleteOptionAction(event)
      })
    },
    resetForm() {
      this.form.date = this.$moment().format('YYYY-MM-DD')
      this.form.type = 1
      this.selectedStates = []
      this.selectedSchedules = []
      this.dialogDate = false
    }
  }
}
</script>

<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  width: 100%;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
}
.btn-add {
  top: 2px !important;
  right: 2px !important;
}
.v-calendar-weekly__day-label {
  cursor: none !important;
}
</style>

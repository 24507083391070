import { render, staticRenderFns } from "./blockSchedule.vue?vue&type=template&id=71216852&scoped=true&"
import script from "./blockSchedule.vue?vue&type=script&lang=js&"
export * from "./blockSchedule.vue?vue&type=script&lang=js&"
import style0 from "./blockSchedule.vue?vue&type=style&index=0&id=71216852&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71216852",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCalendar } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VRadio } from 'vuetify/lib'
import { VRadioGroup } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSheet } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VSubheader } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {VBtn,VCalendar,VCard,VCardActions,VCardText,VCheckbox,VChip,VDataTable,VDivider,VFlex,VIcon,VLayout,VListTile,VListTileAction,VListTileContent,VListTileTitle,VRadio,VRadioGroup,VSelect,VSheet,VSpacer,VSubheader,VTextField})
